/* <============================================== hamburger menu start =====================================================> */
.nav {
  transition: 0.3s ease;
  display: none;
}
nav.navMenu {
  display: block;
}
nav.navMenu ul {
  position: relative;
  right: 30px;
  top: 70px;
  z-index: 9;
  list-style: none;
}
nav.navMenu ul li {
  margin-bottom: 25px;
}
nav.navMenu ul li a {
  color: #000;
  font-size: 25px;
  font-weight: 800;
  text-transform: uppercase;
  text-decoration: none;
}

#menu {
  z-index: 2;
}
.menu-bg, #menu {
  top: 0;
  left: 0;
  position: absolute;
}
.menu-bg {
  z-index: 1;
  width: 0;
  height: 0;
  margin: 30px 0 20px 20px;
  background: radial-gradient(circle, #DC052D, #DC052D);
  border-radius: 50%;
  transition: 0.3s ease-in-out;
}
.change-bg {
  width: 725px;
  height: 745px;
  transform: translate(-60%,-25%);
}

.navBtn {
  position: sticky;
  z-index: 99;
  width: 60px;
  height: 60px;
  top: 20px;
  left: 20px;
  transition: 0.5s ease;
}
.navBtn .icon {
  transition: 0.75s ease;
  position: relative;
  height: 6px;
  width: 45px;
  top: 30px;
  left: 15px;
  background-color: #000;
  z-index: 9;
}
.navBtn .icon:before {
  transition: 0.75s ease;
  position: absolute;
  width: 45px;
  height: 6px;
  background-color: #000;
  content: "";
  top: -16.5px;
  left: 0;
}
.navBtn .icon:after {
  transition: 0.75s ease;
  position: absolute;
  width: 45px;
  height: 6px;
  background-color: #000;
  content: "";
  top: 16.5px;
  left: 0;
}
.navBtn.open .icon {
  transition: 1s ease;
  transform: rotateZ(180deg);
}
.navBtn.open .icon:before {
  transform: rotateZ(45deg) scaleX(0.75) translate(20px, -6px);
}
.navBtn.open .icon:after {
  transform: rotateZ(-45deg) scaleX(0.75) translate(20px, 6px);
}
.navBtn:hover {
  cursor: pointer;
}
/* <=============================================== hamburger menu end ======================================================> */


/* <=============================================== homepage main start ======================================================> */
.homeContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* height: 60rem */
}
/* <=================================================== hero section ==========================================================> */
.heroCurveTop {
  position: relative;
  top: 1rem;
}
.heroCurvBottom {
  position: relative;
  bottom: 2rem;
}

.heroMain {
  background-color: #f8f8f8;
  padding: 60px 25px;
  text-align: center;
  position: relative;
  bottom: 1rem;
  /* box-shadow: 1px 1px 20px 1px #d3d3d3; */
}

.heroContainer {
  max-width: 600px;
  /* margin: 0 auto; */
  max-width: 600px;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding-bottom: 2rem;
}

.heroTitle {
  font-size: 32px;
  color: #333;
  margin-bottom: 20px;
}

.heroDescription {
  font-size: 18px;
  color: #777;
  margin-bottom: 40px;
  word-wrap: break-word;
}

.heroButton {
  background-color: #DC052D;
  color: #fff;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.heroButton:hover {
  background-color: #e69500;
}

/* <============================================= partnered badges section ====================================================> */
.partnerCurveTop {
  position: relative;
  bottom: 8.5rem;
}
.curveContainer {
  width: auto;
  height: auto;
}

.partnerMain {
  height: 9.5rem;
  width: auto;
  position: relative;
  bottom: 9.75rem;
  background-color: #d3d3d3;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.partnerBadges {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow-y: scroll;
}

.partnerLogo {
  width: 50px;
  height: auto;
  margin-right: 1rem;
  margin-left: 1rem;
  filter: gray;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}
.partnerLogo:hover {
  -webkit-filter: grayscale(0);
  filter: none;
}

.baroid {
  position: relative;
  left: .25rem;
  margin-right: .75rem;
}

.mobilequbes,
.aveeno {
  border-radius: 50%;
}

.curveContainerBottom {
  position: relative;
  bottom: 9.75rem;
}

.partnerCurveBottom {
  position: relative;
  /* bottom: 9.75rem; */
}

/* <================================================== services section =======================================================> */

.servicesContainer {
  font-family: Arial, sans-serif;
  max-width: 600px;
  position: relative;
  bottom: 5.5rem;
  margin-right: auto;
  margin-left: auto;
}
.servicesHeading {
  text-align: center;
  color: #333;
  position: relative;
  bottom: 1rem;
}
.serviceItem {
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

.serviceItem.reveal.active {
  margin-bottom: 5em;
}

.serviceName {
  font-size: 18px;
  font-weight: bold;
  color: #dc052d;
  border-bottom: 1.5px solid #9c9c9c;
  padding: 0 0 1rem 0;
  width: 90%;
  margin-right: auto;
  margin-left: auto;
}

.reveal{
  position: relative;
  transform: translateX(-150px);
  opacity: 0;
  transition: 1s all ease;
}
/* .reveal:nth-child(odd) {
  position: relative;
  transform: translateX(150px);
  opacity: 0;
  transition: 1s all ease;
} */
.reveal.active{
  transform: translateX(0);
  opacity: 1;
}

/* <================================================= portfolio section =======================================================> */

.portfolioContainer {
  position: relative;
  bottom: 2.5rem;
  height: 1125px;
}
.portfolioContainer:not(:first-child) {
  background-color: #f8f8f8;
}

.portfolioCurveTop {
  position: relative;
  bottom: 4rem;
}

.portfolioHeader {
  margin-bottom: 5rem;
}

.portfolioItem {
  height: 150px;
}

.portfolioItem,
.portfolioItemNameContainer {
  list-style: none;
  text-align: center;
}

.portfolioItemContainer {
  padding-left: 0;
}

.portfolioImage {
  height: 150px;
  width: auto;
  margin-bottom: 7rem;
}

/* .portfolioItem:not(:nth-child(2)) {
  position: relative;
  transform: rotate(-15deg);
  left: 1rem;
}

.portfolioItem:nth-child(2) {
  position: relative;
  transform: rotate(15deg);
  right: 1rem;
} */

.portfolioImage {
  object-fit: cover;
}

.portfolioDescription {
  font-size: 14px;
  margin-bottom: 5rem;
}

/* <================================================ homepage main end =======================================================> */


/* <================================================ media query start =======================================================> */
@media screen and (min-width: 445px){
  .heroMain {
    padding: 15px 25px 55px 25px;
  }
  .heroMain,
  .heroCurvBottom {
    bottom: unset;
  }

  .serviceItemContainer {
    column-count: 2;
    column-gap: 2rem;
    column-rule: 1.5px solid gray;
  }
  .servicesOffered {
    margin-top: auto;
  }
}
@media screen and (min-width: 600px) {
  .partnerCurveTop {
    bottom: 12rem;
  }
  .partnerMain {
    bottom: 12.5rem;
    justify-content: space-between;
    padding-bottom: 1.5rem;
  }
  .partnerCurveBottom {
    bottom: 3rem;
  }
  .partnerTitle {
    font-size: 1.35rem;
  }
  .partnerLogo {
    width: 70px;
    height: auto;
  }

  .servicesHeading {
    bottom: 3.5rem;
  }
  .serviceItemContainer {
    column-count: 2;
    column-rule: 1.5px solid #9c9c9c;
  }
  .servicesOffered {
    /* margin-top: auto; */
    border-bottom: 1.5px solid #9c9c9c;
    display: grid;
    padding-bottom: 1em;
    /* width: 50%; */
    /* margin-left: auto; */
    /* margin-right: auto; */
    /* padding: 0.5rem 0 1rem 0; */
  }
  .serviceName {
    margin-bottom: 1em;
  }
}
@media screen and (min-width: 850px) {
  .heroMain {
    padding: 0px 25px 20px 25px;
  }
  .partnerCurveTop {
    bottom: 16.5rem;
  }
  .partnerMain {
    bottom: 17rem;
    padding-bottom: 2.5rem;
  }
  .partnerCurveBottom {
    bottom: 7.5rem;
  }
  .servicesContainer {
    bottom: 11rem;
  }
}

@media screen and (min-width: 1200px) {
  body {
    width: calc(100vw - 25%);
    margin-left: auto;
    margin-right: auto;
  }
}
/* <================================================= media query end ========================================================> */
